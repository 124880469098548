
/* Popup style */
.popup-box {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top:  90px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* background-color: rgba(228, 15, 15, 0.4);
    background-color: rgba(228, 15, 15, 0.4); */
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.3);
  }
  
  .box {
    display: block;
  position: relative;
  /* width: 70%; */
  margin: 0 auto;
  height: 85%;
  /* height: auto; */
  /* max-height: 70vh; */
  /* margin-top: calc(100vh - 85vh - 20px); */
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  width:  500px;
  }